import { onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import dayjs from "dayjs";
import Handsontable from "handsontable";

import { showError, showWarning } from "@/libs/notification";
import { exportExcel } from "@/libs/export";

import { DATE_FORMAT } from "@/configs/constants";
import {
  OrderPayload,
  OrderSearchForm,
  TableColumn,
} from "@/configs/orders";
import { Pagination } from "@/configs/common";
import { ExportField } from "@/configs/jtTypes";
import { helper as $h } from "@/utils/helper";
import { columnConfigs, columnWidths } from "./columns";
import { partSignService } from "@/services/partsign";

export const usePartsign: any = () => {
  const loading = ref(false);
  const hot = ref();
  const router = useRouter();
  const tableRef = ref<HTMLElement>();
  const today = dayjs().format(DATE_FORMAT);
  const formData = reactive(<OrderSearchForm>{
    dateRange: `${today} - ${today}`,
  });
  const pagination = ref(<Pagination>{
    page: 1,
    pageSize: 20,
  });
  const orders = ref<any[]>([]);
  const columns = ref<TableColumn[]>(columnConfigs);

  // Get Orders
  const preparePayload = (): OrderPayload => {
    const [startDate, endDate] = $h.dateRangeToDate(formData.dateRange);
    return {
      search: formData.query || undefined,
      page: pagination.value.page || 1,
      pageSize: pagination.value.pageSize || 20,
      startDate,
      endDate,
    };
  };

  const getOrders = async () => {
    loading.value = true;
    hot.value.loadData([]);
    try {
      const result = (await partSignService.getPartSigns(preparePayload())).result;
      orders.value = result.orders;
      pagination.value.total = result.total;
      hot.value.loadData(orders.value);
    } catch (error) {
      showError(error.response ? error.response.data.error : "Co loi xay ra");
    } finally {
      loading.value = false;
    }
  };

  const search = () => {
    getOrders();
  };

  const exportSelected = (columns: ExportField[]) => {
    const selectedRows = orders.value.filter((item) => item.select);
    if (!selectedRows || selectedRows.length === 0) {
      showWarning("Vui lòng chọn đơn cần truy xuất");
      return;
    }
    exportExcel(selectedRows, columns);
    orders.value.forEach((e) => (e.select = false));
  };

  const exportAll = async (columns: ExportField[]) => {
    loading.value = true;
    try {
      const payload = { ...preparePayload(), pageSize: 10000 };
      const result = (await partSignService.getPartSigns(payload)).result;
      exportExcel(result.orders, columns);
    } catch (error) {
      showError(error.response ? error.response.data.error : "Co loi xay ra");
    } finally {
      loading.value = false;
    }
  };

  // Pagination
  const handlePaginationChange = (newVal: Pagination) => {
    pagination.value = { ...pagination.value, ...newVal };
    getOrders();
  };

  // Handson Table
  const isChecked = () => {
    const find = orders.value.find((item) => !item.select);
    return !find;
  };

  const colHeaders = (col: number) => {
    let txt;

    switch (col) {
      case 0:
        txt = "<input type='checkbox' class='checker' ";
        txt += isChecked() ? 'checked="checked"' : "";
        txt += ">";

        return txt;

      default:
        return columns.value[col].header || "";
    }
  };

  const initHandson = () => {
    if (tableRef.value) {
      hot.value = new Handsontable(tableRef.value, {
        rowHeaders: true,
        colHeaders: colHeaders,
        columns: columns.value,
        afterOnCellMouseDown: function (e, coords) {
          if (coords.col === 1) {
            const billCode = hot.value.getDataAtRow(coords.row)[1];
            router.push({
              name: "order-tracking",
              query: {
                billCode: billCode,
              },
            });
          }
        },
        colWidths: columnWidths,
        rowHeights: 35,
        stretchH: "all",
        className: "htMiddle handsongray",
        fixedColumnsLeft: 3,
        licenseKey: "non-commercial-and-evaluation",
        width: "100%",
        height: "100%",
      });
      Handsontable.dom.addEvent(
        tableRef.value,
        "mousedown",
        function (event: any) {
          if (
            event.target.nodeName === "INPUT" &&
            event.target.className === "checker"
          ) {
            event.stopPropagation();
          }
        }
      );

      Handsontable.dom.addEvent(
        tableRef.value,
        "mouseup",
        function (event: any) {
          if (
            event.target.nodeName === "INPUT" &&
            event.target.className === "checker"
          ) {
            const check = isChecked();
            orders.value.forEach((e) => (e.select = !check));
            hot.value.render();
          }
        }
      );
    }
  };

  watch(
    () => formData.query,
    (val: any) => {
      formData.query = val.replace(" ", ",").trim();
    }
  );

  onMounted(() => {
    initHandson();
    getOrders();
  });

  return {
    tableRef,
    pagination,
    search,
    handlePaginationChange,
    formData,
    loading,
    columns,
    orders,
    exportSelected,
    exportAll,
  };
};
