
import { computed, defineComponent, ref } from "vue";
import {
  defaultExportColumns,
  allExportColumns,
} from "./columns";
import { showError } from "@/libs/notification";

export default defineComponent({
  name: "OrderModal",
  setup(props, { emit }) {
    const checked = ref<string[]>(allExportColumns.map(item => item.fieldName));

    const hideModal = () => {
      cash("#export-modal").modal("hide");
    };

    const exportAll = () => {
      if (checked.value.length === 0) {
        showError("Vui lòng chọn trường cần truy xuất");
        return;
      }
      emit(
        "export-all",
        allExportColumns.filter((item) =>
          checked.value.includes(item.fieldName)
        )
      );
      cash("#export-modal").modal("hide");
    };

    const exportSelected = () => {
      if (checked.value.length === 0) {
        showError("Vui lòng chọn trường cần truy xuất");
        return;
      }
      emit(
        "export-selected",
        allExportColumns.filter((item) =>
          checked.value.includes(item.fieldName)
        )
      );
      cash("#export-modal").modal("hide");
    };

    const toggleSelectAll = () => {
      if (allChecked.value) {
        checked.value = [];
      } else {
        checked.value = [];
        defaultExportColumns.forEach((groups) => {
          groups.forEach((item) => {
            checked.value.push(item.fieldName);
          });
        });
      }
    };

    const allChecked = computed(() => checked.value.length === 12);

    return {
      hideModal,
      exportAll,
      exportSelected,
      checked,
      allChecked,
      toggleSelectAll,
      defaultExportColumns,
    };
  },
});
