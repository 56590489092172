export const trackingRenderer = (
  _instance: unknown,
  td: { className: string; innerHTML: string }
): void => {
  td.className = "htMiddle";
  td.innerHTML = `<a href="javascript:void(0);" style="color:blue">Tra hành trình</a>`;
};

export const addressRenderer = (
  _instance: unknown,
  td: any,
  _row: number,
  _col: number,
  _prop: string,
  value: string
): void => {
  td.className = "truncate htMiddle";
  // eslint-disable-next-line no-control-regex
  td.innerHTML = value?.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, ",");
};

export const columnWidths = [
  30,
  120,
  120,
  180,
  180,
  120,
  120,
  180,
  180,
  180,
  500,
  300,
  120,
  120,
];

export const columnConfigs = [
  {
    header: "",
    data: "select",
    type: "checkbox",
  },
  {
    header: "Thao tác",
    data: "oldBillCode",
    readOnly: true,
    renderer: trackingRenderer,
  },
  {
    header: "Mã đơn cũ",
    data: "oldBillCode",
    readOnly: true,
  },
  {
    header: "Mã đơn mới",
    data: "newBillCode",
    readOnly: true,
  },
  {
    header: "Thời gian",
    data: "checkInTime",
    readOnly: true,
  },
  {
    header: "Điện thoại gửi",
    data: "senderTel",
    readOnly: true,
  },
  {
    header: "Người gửi",
    data: "senderName",
    readOnly: true,
  },
  {
    header: "Tỉnh/Thành Phố gửi",
    data: "senderProvince",
    readOnly: true,
  },
  {
    header: "Quận/Huyện gửi",
    data: "senderCity",
    readOnly: true,
  },
  {
    header: "Xã/Phường gửi",
    data: "senderArea",
    readOnly: true,
  },
  {
    header: "Địa chỉ gửi",
    data: "senderAddress",
    renderer: addressRenderer,
    readOnly: true,
  },
  {
    header: "Ghi Chú",
    data: "remark",
    readOnly: true,
  },
  {
    header: "Loại hàng",
    data: "category",
    readOnly: true,
  },
  {
    header: "Dịch vụ",
    data: "expressType",
    readOnly: true,
  },
];
