
import { defineComponent } from "vue";
import { usePartsign } from "./usePartsign";
import Pagination from "@/components/pagination/Main.vue";
import ExportPartsignModal from "@/components/export-partsign-modal/Main.vue";
import "handsontable/dist/handsontable.full.css";

export default defineComponent({
  components: {
    Pagination,
    ExportPartsignModal,
  },
  setup() {
    const {
      tableRef,
      formData,
      handlePaginationChange,
      search,
      pagination,
      columns,
      loading,
      exportSelected,
      exportAll,
      selectedOrder,
    } = usePartsign();
    return {
      tableRef,
      formData,
      handlePaginationChange,
      columns,
      pagination,
      loading,
      search,
      exportSelected,
      exportAll,
      selectedOrder,
    };
  },
  methods: {
    showExportModal() {
      cash("#export-modal").modal("show");
    },
  },
});
