import { ref, toRefs, watch } from "vue";
import { Pagination } from "@/configs/common";

export const usePagination: any = (props: any, { emit }: any) => {
  const maxPage = ref(0);
  const showPages = ref([] as any);
  const pageSizes = ref([20, 50, 100, 500]);

  const { pagination } = toRefs(props);

  watch(
    pagination,
    (val) => {
      maxPage.value = Math.floor(val.total / val.pageSize) + 1;
      calculateShow(val.page, maxPage.value);
    },
    { deep: true }
  );

  const calculateShow = (page: number, maxPage: number) => {
    let result: number[];
    if (page <= 4) result = [1, 2, 3, 4, 5];
    else if (maxPage - page < 4)
      result = [maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
    else result = [page - 2, page - 1, page, page + 1, page + 2];

    result = result.filter((e) => e > 0 && e <= maxPage);

    showPages.value = result.sort((a, b) => {
      return a - b;
    });
  };

  const changePagination = (newVal: Pagination) => {
    emit("handlePaginationChange", newVal);
  };

  const onPageSizeChange = (event: any) => {
    changePagination({ page: 1, pageSize: event.target.value });
  };

  return {
    maxPage,
    showPages,
    pageSizes,
    onPageSizeChange,
    changePagination,
  };
};
