import { ExportField } from "@/configs/jtTypes";
import { addressRenderer } from "@/views/partsign/columns";

const left = [
  {
    fieldName: "newBillCode",
    header: "Mã đơn mới",
  },
  {
    fieldName: "oldBillCode",
    header: "Mã đơn cũ",
  },
  {
    fieldName: "checkInTime",
    header: "Thời gian",
  },
  {
    fieldName: "remark",
    header: "ghi chú",
  },
];
const mid = [
  {
    header: "Điện thoại gửi",
    fieldName: "senderTel",
  },
  {
    header: "Người gửi",
    fieldName: "senderName",
  },
  {
    header: "Tỉnh/Thành Phố gửi",
    fieldName: "senderProvince",
  },
  {
    header: "Quận/Huyện gửi",
    fieldName: "senderCity",
  },
  {
    header: "Xã/Phường gửi",
    fieldName: "senderArea",
  },
  {
    header: "Địa chỉ gửi",
    fieldName: "senderAddress",
    renderer: addressRenderer,
  },
];

const right = [
  {
    header: "Loại hàng",
    fieldName: "category",
  },
  {
    header: "Dịch vụ",
    fieldName: "expressType",
  },
];

export const defaultExportColumns: ExportField[][] = [left, mid, right];

export const allExportColumns: ExportField[] = [...left, ...mid, ...right];
