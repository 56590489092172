<template>
  <div id="export-modal" class="modal" style="z-index: 9000">
    <div class="modal__content modal__content--lg p-10 text-center">
      <div class="text-left mb-5">
        <input
          class="show-code input border"
          type="checkbox"
          :checked="allChecked"
          @click="toggleSelectAll"
        />
        Chọn tất cả
      </div>
      <div class="grid grid-cols-3 gap-4 mb-5">
        <div v-for="(groups, index) in defaultExportColumns" :key="index">
          <div class="flex-col space-y-1">
            <div
              v-for="(field, index) in groups"
              :key="index"
              class="text-left"
            >
              <input
                class="show-code input border"
                type="checkbox"
                :value="field.fieldName"
                v-model="checked"
              />
              {{ field.header }}
            </div>
          </div>
        </div>
      </div>
      <a
        href="javascript:;"
        class="button w-24 mr-4 inline-block bg-theme-1 text-white"
        @click="exportAll"
      >
        Truy xuất
      </a>
      <a
        href="javascript:;"
        class="button mr-4 inline-block bg-theme-31 text-theme-6"
        @click="exportSelected"
      >
        Truy xuất đơn đã chọn
      </a>
      <a
        href="javascript:;"
        class="button w-24 mr-4 inline-block bg-theme-2 text-gray-600"
        @click="hideModal"
      >
        Huỷ
      </a>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import {
  defaultExportColumns,
  allExportColumns,
} from "./columns";
import { showError } from "@/libs/notification";

export default defineComponent({
  name: "OrderModal",
  setup(props, { emit }) {
    const checked = ref<string[]>(allExportColumns.map(item => item.fieldName));

    const hideModal = () => {
      cash("#export-modal").modal("hide");
    };

    const exportAll = () => {
      if (checked.value.length === 0) {
        showError("Vui lòng chọn trường cần truy xuất");
        return;
      }
      emit(
        "export-all",
        allExportColumns.filter((item) =>
          checked.value.includes(item.fieldName)
        )
      );
      cash("#export-modal").modal("hide");
    };

    const exportSelected = () => {
      if (checked.value.length === 0) {
        showError("Vui lòng chọn trường cần truy xuất");
        return;
      }
      emit(
        "export-selected",
        allExportColumns.filter((item) =>
          checked.value.includes(item.fieldName)
        )
      );
      cash("#export-modal").modal("hide");
    };

    const toggleSelectAll = () => {
      if (allChecked.value) {
        checked.value = [];
      } else {
        checked.value = [];
        defaultExportColumns.forEach((groups) => {
          groups.forEach((item) => {
            checked.value.push(item.fieldName);
          });
        });
      }
    };

    const allChecked = computed(() => checked.value.length === 12);

    return {
      hideModal,
      exportAll,
      exportSelected,
      checked,
      allChecked,
      toggleSelectAll,
      defaultExportColumns,
    };
  },
});
</script>
