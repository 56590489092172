<template>
  <!-- BEGIN: Pagination -->
  <div
    class="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-6"
  >
    <ul class="pagination">
      <li>
        <a
          class="pagination__link"
          @click="changePagination({ page: pagination.page - 1 })"
          v-if="pagination.page > 1"
        >
          <ChevronLeftIcon class="w-4 h-4" />
        </a>
      </li>
      <template v-if="pagination.page > 4">
        <li>
          <a class="pagination__link" @click="changePagination({ page: 1 })">
            1
          </a>
        </li>
        <li>
          <a
            class="pagination__link"
            @click="changePagination({ page: Math.max(pagination.page - 4) })"
            >...</a
          >
        </li>
      </template>
      <li v-for="page in showPages" :key="page">
        <a
          class="pagination__link"
          :class="pagination.page === page ? 'pagination__link--active' : ''"
          @click="changePagination({ page })"
        >
          {{ page }}
        </a>
      </li>
      <template v-if="pagination.page < maxPage - 2">
        <li>
          <a
            class="pagination__link"
            @click="
              changePagination({ page: Math.min(pagination.page + 4, maxPage) })
            "
            >...</a
          >
        </li>
        <li>
          <a
            class="pagination__link"
            @click="changePagination({ page: maxPage })"
            >{{ maxPage }}</a
          >
        </li>
      </template>

      <li>
        <a
          class="pagination__link"
          v-if="pagination.page < maxPage"
          @click="changePagination({ page: pagination.page + 1 })"
        >
          <ChevronRightIcon class="w-4 h-4" />
        </a>
      </li>
    </ul>
    <span> Tổng số bản ghi: {{ pagination.total }}</span>
    <select
      @change="onPageSizeChange($event)"
      class="w-30 input box ml-3 mt-3 sm:mt-0"
    >
      <option
        v-for="pageSize in pageSizes"
        :key="pageSize"
        :value="pageSize"
        :selected="pageSize === pagination.pageSize"
      >
        {{ pageSize }} / Trang
      </option>
    </select>
  </div>
  <!-- END: Pagination -->
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Pagination } from "@/configs/common";
import { usePagination } from "./usePagination";

export default defineComponent({
  name: "Pagination",
  props: {
    pagination: {
      required: true,
      type: Object as PropType<Pagination>,
    },
  },
  setup(props, { emit }) {
    const {
      maxPage,
      showPages,
      pageSizes,
      changePagination,
      onPageSizeChange,
    } = usePagination(props, {
      emit,
    });
    return {
      maxPage,
      showPages,
      changePagination,
      onPageSizeChange,
      pageSizes,
    };
  },
});
</script>
