
import { defineComponent, PropType } from "vue";
import { Pagination } from "@/configs/common";
import { usePagination } from "./usePagination";

export default defineComponent({
  name: "Pagination",
  props: {
    pagination: {
      required: true,
      type: Object as PropType<Pagination>,
    },
  },
  setup(props, { emit }) {
    const {
      maxPage,
      showPages,
      pageSizes,
      changePagination,
      onPageSizeChange,
    } = usePagination(props, {
      emit,
    });
    return {
      maxPage,
      showPages,
      changePagination,
      onPageSizeChange,
      pageSizes,
    };
  },
});
