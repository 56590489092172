<template>
  <div class="flex-col justify-between mt-2">
    <!-- BEGIN: Filter -->
    <div class="intro-y box px-5 pt-2">
      <div
        class="grid grid-cols-12 border-b border-gray-200 dark:border-dark-5 pb-2 -mx-5 gap-2 pl-2"
      >
        <div class="col-span-5">
          <input
            v-model="formData.query"
            type="text"
            class="input w-full border"
            placeholder="Nhập số theo dõi, số điện thoại hoặc mã khách hàng"
          />
        </div>
        <div class="col-span-2">
          <Litepicker
            v-model="formData.dateRange"
            :options="{
              singleMode: false,
              numberOfColumns: 2,
              numberOfMonths: 2,
              showWeekNumbers: true,
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }"
            class="input w-full border block mx-auto"
          />
        </div>
        <div class="col-span-2">
          <button
            type="button"
            class="button bg-theme-1 flex justify-center text-white"
            @click="search"
          >
            <LoadingIcon color="white" class="w-4 h-4 mr-1" v-if="loading" />
            <SearchIcon class="w-4 h-4 mr-1" v-else /> Tìm kiếm
          </button>
        </div>
      </div>
    </div>
    <!-- END: Filter -->
    <!-- BEGIN: Function Button -->
    <div class="intro-y flex flex-col-reverse sm:flex-row items-center mt-4">
      <div class="w-full sm:w-auto flex">
        <button
          class="button button--sm text-white bg-theme-4 items-center justify-center flex shadow-md mr-2"
          @click="showExportModal"
        >
          <LoadingIcon color="white" class="w-4 h-4 mr-1" v-if="loading" />
          <FileTextIcon class="w-4 h-4 mr-1" v-else /> Truy xuất
        </button>
      </div>
    </div>
    <div class="box mt-4 handson z-0">
      <div
        id="handson"
        ref="tableRef"
        class="hot handsontable htRowHeaders htColumnHeaders"
        style="height: 100%; overflow: hidden; width: 100%"
        data-originalstyle="height: 100%; overflow: hidden; width: 100%;"
      ></div>
    </div>
    <!-- End: Handson Table -->
    <div>
      <Pagination
        :pagination="pagination"
        @handlePaginationChange="handlePaginationChange"
      />
    </div>

  </div>
  <ExportPartsignModal @export-all="exportAll" @export-selected="exportSelected" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { usePartsign } from "./usePartsign";
import Pagination from "@/components/pagination/Main.vue";
import ExportPartsignModal from "@/components/export-partsign-modal/Main.vue";
import "handsontable/dist/handsontable.full.css";

export default defineComponent({
  components: {
    Pagination,
    ExportPartsignModal,
  },
  setup() {
    const {
      tableRef,
      formData,
      handlePaginationChange,
      search,
      pagination,
      columns,
      loading,
      exportSelected,
      exportAll,
      selectedOrder,
    } = usePartsign();
    return {
      tableRef,
      formData,
      handlePaginationChange,
      columns,
      pagination,
      loading,
      search,
      exportSelected,
      exportAll,
      selectedOrder,
    };
  },
  methods: {
    showExportModal() {
      cash("#export-modal").modal("show");
    },
  },
});
</script>
